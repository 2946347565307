import React from 'react';
import './index.scss';

export default () => {
  return (
    <div className="footer">
      ©2020 by <a href="https://github.com/maxxiaobao">maxxiaobao</a>
    </div>
  );
};
